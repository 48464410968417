:root {
    --primary: #2f194a;
    --secondary: #231239;
    --thirdary: #4e0d79;
    --fourthary: #6d13aa;
    --fifthary: #1e0031;
    --light: rgb(204, 204, 204);
    --second-light: "#eee";
    --third-light: "#898888";
    --fourth-light: "#c56ffb";
    --fifth-light: "#9644cc";
    --sixth-color: "#5f0997";
    --seventh-color: "#500282";
    --text-color-light: #AFA5D9;
    --text-color-theme: #333;
    --darkest: #231239;
    --sidebar-width: 60px;
    --image-cover-transparent: rgba(47,25,74,0);
    --image-cover-not-transparent: rgba(47,25,74,1);
    --loading: none;
    --search-pos: 0;
    --loader: 1;
}

body {
    background-color: var(--primary);
    color: var(--light);
    overflow-x: hidden;
    font-family: 'Montserrat', sans-serif;
}

.masonry-row {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
    overflow: hidden;
}
.masonry-row_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}
  
/* Style your items */
.masonry-row_column > div { /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 30px;
}

/* Sidebar */
.sidebar {
    position: fixed;
    height: 100%;
    width: var(--sidebar-width);
    background-color: var(--secondary);
    z-index: 53;
    transition: 0.5s;
    -webkit-box-shadow: 8px 8px 33px -8px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 8px 8px 33px -8px rgba(0, 0, 0, 0.24);
    box-shadow: 8px 8px 33px -8px rgba(0, 0, 0, 0.24);
}

.main-container {
    margin-left: var(--sidebar-width);
    width: calc(100% - var(--sidebar-width));
    transition: 0.5s;
}

/* Burger */

.ham {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    margin-left: 0.5rem;
    height: 2.5rem;
}

.hamRotate.active {
    transform: rotate(45deg);
}

.hamRotate180.active {
    transform: rotate(180deg);
}

.line {
    fill:none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: var(--light);
    stroke-width:5.5;
    stroke-linecap:round;
}

.ham:hover {
    transform: scale(1.1);
    color: red;
}

.ham7 .top {
    stroke-dasharray: 40 82;
}

.ham7 .middle {
    stroke-dasharray: 40 111;
}

.ham7 .bottom {
    stroke-dasharray: 40 161;
}

.ham7.active .top {
    stroke-dasharray: 17 82;
    stroke-dashoffset: -62px;
}

.ham7.active .middle {
    stroke-dashoffset: 23px;
}

.ham7.active .bottom {
    stroke-dashoffset: -83px;
}

.label {
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
}

.nav-item {
    color: var(--text-color-light);
    font-size: 1.25rem;
    display: flex;
    position: relative;
    left: 1.3rem;
    margin-top: 2rem;
    text-decoration: none;
    transition: 0.4s;
    width: 20rem;
}

.nav-item:hover {
    color: var(--second-light);
}

.active {
    color: var(--second-light);
}

.nav-label {
    margin-left: 1.4rem;
    margin-top: 0.2rem;
    font-size: 1rem;
}

.nav-copy {
    color: var(--text-color-light);
    display: flex;
    position: absolute;
    margin: auto;
    bottom: 1%;
    left: 0.6rem;
    opacity: 0.8;
    transition: 0.2s;
}

.nav-copy:hover {
    color: var(--light);
    opacity: 1;
}

.nav-logo {
    width: 2rem;
}

.error-image {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    filter: brightness(0.7);
    width: 30%;
}

.btn-primary, .btn-primary:active, .btn-primary:focus {
    background-color: var(--thirdary);
    border-color: var(--primary);
    outline: none;
    color: var(--text-color-light);
    margin: 1rem;
}

.btn-primary:hover {
    background-color: var(--fourthary) !important;
    border-color: var(--primary) !important;
}

.load {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    z-index: 10;
}

.image-details {
    pointer-events: all;
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 2rem;
    color: white;
}

.image-details a {
    text-decoration: none;
}

.character-name {
    font-size: 1.3rem;
    color: white;
    transition: 0.2s;
}

.franchise-name {
    font-size: 0.9rem;
    color: var(--light);
    transition: 0.2s;
}

.character-name:hover, .franchise-name:hover {
    color: var(--text-color-light);
}

.author-name {
    margin-top: 1rem;
    font-size: 0.9rem;
    color: var(--light);
}

.loading {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    z-index: 50;
    transition: 0.5s;
}

.big-container {
    background-color: var(--secondary);
    padding: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 2rem;
    text-align: center;
    transition: 0.4s;
}

.big-image {
    max-width: 100%;
    max-height: 80vh;
    border-radius: 2rem;
    border: 1px solid var(--thirdary);
    -webkit-box-shadow: 8px 8px 33px -8px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 8px 8px 33px -8px rgba(0, 0, 0, 0.24);
    box-shadow: 8px 8px 33px -8px rgba(0, 0, 0, 0.24);
}

.colored {
    color: var(--text-color-light)
}

.user-pfp {
    border-radius: 100%;
    width: 6rem;
    height: 6rem;
    border: 1px solid var(--fifthary);
}

.profile {
    margin-top: 1rem;
    margin-left: 3rem;
}

.user-info {
    background-color: var(--fifthary);
    border-radius: 0 2rem 2rem 0;
    border: 1px solid var(--primary);
    width: auto;
}

.user-details {
    text-align: left;
    padding-top: 0.7rem;
}

.label {
    color: var(--light)
}

.char-pfp {
    width: 100%;
    height: 9rem;
    object-fit: cover;
    background-color: var(--fifthary);
    border: 1px solid var(--primary);
    border-radius: 1rem;
}

.my-link {
    text-decoration: none;
    color: var(--text-color-light);
    transition: 0.2s;
}

.my-link:hover {
    color: var(--light);
}

.stat {
    padding: 0.3rem;
    background-color: var(--fifthary);
    border: 1px solid var(--primary);
    border-radius: 1rem;
}

.btn-disabled, .btn-disabled:hover, .btn-disabled:active, .btn-disabled:focus {
    background-color: var(--fifthary);
    pointer-events: none;
    cursor: not-allowed !important;
    color: rgb(117, 109, 117);
}

.my-spinner {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.floater {
    position: fixed;
    margin: auto;
    left: var(--sidebar-width);
    top: calc(var(--search-pos) - 8px);
    z-index: 51;
    background-color: var(--fifthary);
    padding: 0.8rem 0.5rem;
    padding-right: 1rem;
    border-radius: 0 1rem 1rem 0;
    -webkit-box-shadow: 8px 8px 33px -8px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 8px 8px 33px -8px rgba(0, 0, 0, 0.24);
    box-shadow: 8px 8px 33px -8px rgba(0, 0, 0, 0.24);
    transition: 0.5s;
    display: flex;
    line-height: normal;
    height: 3rem;
    /* margin-left: 10rem; */
    margin-left: -4.4rem;
}

.floater .fa-solid {
    line-height: 1.3 !important;
}

.floater:hover {
    cursor:pointer;
    background-color: var(--fourthary);
    margin-left: 0;
}

.floater-active {
    margin-left: 12rem !important;
    /* background-color: var(--fifthary) !important; */
}

.no-pointer {
    pointer-events: none;
}

.search {
    padding-top: 0;
}

.search input, .search input:active, .search input:focus {
    background-color: var(--secondary);
    color: var(--text-color-light);
}

.line {
    border-top-color: black !important;
}

.selected {
    background-color: var(--fourthary) !important;
    cursor:pointer;
}

.search .ellipsis {
    cursor: pointer;
}

.title {
    color: var(--text-color-light);
    font-size: 2rem;
    font-weight: 600;
    display: flex;
}

.mini-search-img {
    width: 2rem;
    max-height: 3rem;
    object-fit: cover;
    border-radius: 0.5rem;
    margin-right: 1rem;
}

.choice {
    background-color: var(--secondary);
    border: 1px solid var(--fifthary);
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    width: fit-content;
    border-radius: 2rem;
    transition: 0.2s;
    margin-right: 1rem;
}

.choice:hover {
    background-color: var(--fourthary);
    cursor:pointer;
}

.type-info {
    color: rgb(168, 166, 166);
    font-size: 0.7rem;
    text-transform: capitalize;
}

.flag {
    font-size: 0.9rem;
    color: var(--primary);
    background-color: var(--text-color-light);
    width: fit-content;
    height: fit-content;
    padding: 0.3rem 0.6rem;
    padding-right: 0.1rem;
    border-radius: 0.5rem;
    font-weight: 800;
    cursor: pointer;
    margin-left: 1rem;
    margin-top: 0.5rem;
    transition: 0.2s;
}

.flag:hover {
    opacity: 0.8;
}

.bottom-part {
    margin-top: 32rem;
}

.section-holder {
    padding: 3rem;
}

.panel, .panel-second {
    margin: 1rem;
    background-color: var(--secondary);
    border: 1px solid var(--fifthary);
    padding: 2rem;
    border-radius: 1rem;
    width: 100%;
    text-align: center;
    transition: 0.4s;
}

.panel:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.panel i, .panel svg {
    transition: 0.4s;
}

.panel:hover > i, .panel:hover > svg {
    color: var(--text-color-light);
    transform: rotate(180deg);
}

.panel-icon, .panel svg {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.no-link, .no-link:hover {
    color: var(--light);
    text-decoration: none !important
}

.small-question {
    margin-top: 0.6rem;
    font-weight: 700;
    color: var(--text-color-light);
    font-size: 0.8rem;
}

.small-answer {
    font-size: 0.9rem;
}

.big-panel {
    background-color: var(--secondary);
    border: 1px solid var(--fifthary);
    padding: 2rem;
    border-radius: 1rem;
}

.selected-items {
    display: flex;
}

.selected-items .choice {
    margin-top: 0.5rem!important;
    background-color: var(--fourthary);
}

.selected-items .choice:hover {
    background-color: var(--primary);
}

.big-panel .title {
    text-align: center;
}

.form-control, .form-control:focus {
    background-color: var(--fifthary);
    border: 1px solid black;
    border-radius: 2rem;
    color: var(--text-color-light)
}

input::file-selector-button {
    background-color: var(--primary)!important;
    color: var(--text-color-light)!important;
}

.upload-form input, .upload-form textarea {
    background-color: var(--fifthary);
    border: 1px solid black;
    border-radius: 2rem;
    color: var(--text-color-light);
    width: 100%;
    height: 2.5rem;
    padding-left: 1rem;
    transition: 0.2s;
}

.upload-form textarea {
    min-height: 5rem;
    padding: 1rem;
}

.upload-form textarea:focus {
    outline: none;
}

.upload-form input[type="submit"]:hover {
    background-color: var(--fourthary);
}

.upload-form input:focus {
    outline: none;
}

.upload-form input[type="file"] {
    padding-left: 0.5rem;
}

.upload-form input[type="range"] {
    -webkit-appearance: none;
    background-color: var(--fifthary);
    padding-left: 0;
    height: 2rem;
}

input[type="range"]::-webkit-slider-thumb {
    background-color: var(--text-color-light);
}

input[type=range]::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: 1px solid black;
    background: transparent;
}

.upload-form input[type="submit"] {
    width: auto;
    padding-left: 2rem;
    padding-right: 2rem;
}

.preview {
    max-width: 100%;
    max-height: 30rem;
    object-fit: cover;
    border: 1px solid black;
    border-radius: 2rem;
}

.ReactTags__tag {
    background-color: var(--fourthary);
    padding: 0.5rem;
    padding-left: 1rem;
    border-radius: 1rem;
    margin: 0.2rem;
}

.ReactTags__remove {
    background: none;
    border: none;
    color: var(--light)
}

.ReactTags__tagInput {
    margin-top: 1rem;
}

.ReactTags__suggestions {
    background-color: var(--fifthary);
    border-radius: 0.5rem;
    border: 1px solid black;
    padding-top: 1rem;
}

.ReactTags__activeSuggestion {
    background-color: var(--fourthary);
    border-radius: 0.5rem;
    cursor: pointer;
}

.ReactTags__suggestions li mark {
    background: var(--text-color-light);
    border-radius: 0.5rem;
}


.upload-form .badge {
    background-color: var(--fourthary) !important;
    color: var(--text-color-light);
}

.upload-form svg {
    fill: var(--text-color-light) !important;
}

.cursor-pointer {
    cursor:pointer;
}

.rc-slider-mark-text {
    color: var(--text-color-light);
    top: 15px;
}

.rc-slider-mark-text-active {
    color: var(--light);
}

.rc-slider {
    height: 3rem;
}

.rc-slider-rail {
    background-color: var(--fifthary) !important;
}

.rc-slider-track {
    background-color: var(--fourthary) !important;
}

.rc-slider-handle {
    border: 2px solid var(--fourthary);
    width: 20px !important;
    margin-top: 0;
}

.rc-slider-rail, .rc-slider-handle, .rc-slider-track, .rc-slider-step {
    height: 20px!important;
}

.rc-slider-dot {
    height: 14px!important;
    width: 14px!important;
    bottom: 3px;
}

.rc-slider-dot-active {
    border-color: var(--fourthary);
}

input[type="color"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 1rem;
    padding-left: 0 !important;
}

input[type="color"]::-webkit-color-swatch {
    border-radius: 1rem;
    border: 1px solid black;
}

.item-image {
    margin-top: 1rem;
    width: 100%;
    height: 20vw;
    object-fit: cover;
    border: 2px solid var(--fifthary);
    transition: 0.2s;
    background-color: var(--secondary);
}

.item-image:hover {
    transform: scale(1.02);
    cursor: pointer;
}

.break {
    margin-top: 3rem;
}

.page-control .btn-primary {
    margin: 0.2rem;
}

.big-cover {
    border-radius: 2rem;
    border: 3px solid var(--fifthary);
    max-width: 100%;
}

.item-details {
    text-align: left;
    margin-top: 1rem;
    font-size: 0.9rem;
}

.bold {
    font-weight: 600;
    margin-bottom: 0;
}

.frail {
    font-size: 0.8rem;
    background-color: var(--fifthary);
    margin: 0.1rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    transition: 0.1s;
}

.frail:hover {
    background-color: var(--fourthary);
}

.edit-image {
    max-width: 25%;
    text-align: center;
    margin: auto;
    border-radius: 2rem;
}

.panel-disabled {
    pointer-events: none !important;
    filter: brightness(0.8);
    opacity: 0.5;
    cursor: not-allowed !important;
}

input[type="radio"] {
    width: 1rem !important;
    height: 1rem !important;
}

.form-check-input:checked {
    background-color: var(--text-color-light);
    border-color: var(--fourthary);
}

.highlight {
    color: #00ffbb;
}

.red {
    color: rgb(255, 115, 115);
}

.green {
    color: rgb(145, 255, 145);
}

::placeholder {
    color: var(--third-light);
}

/* HOME */
.myNav {
    background-color: var(--secondary);
}


.title-img {
    max-width: 18rem;
    margin-bottom: -2.5rem;
}
  
.title-div {
    text-align: center;
    color: white;
}

.title-div h1 {
    font-weight: 700;
    font-size: 3.5rem;
}

.title-div h2, .dark h2, .section-title {
    margin-top: -0.8rem;
    font-size: 2.5rem;
    font-weight: 700;
    color: white;
}

.dark h3 {
    font-weight: 700;
}

.title-div p {
    font-size: 1.15rem;
}

.extra-colored {
    color: var(--fourth-light) !important;
}

.btn-warning, .btn-warning:active, .btn-warning:focus {
    background-color: var(--fifth-light);
    border-color: var(--primary);
    outline: none;
    color: white;
}

.btn-warning:hover {
    background-color: var(--fourthary) !important;
    border-color: var(--primary) !important;
    color: white;
}

.big-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--primary);
    transition: 0.4s;
    opacity: var(--loader);
    pointer-events: none;
    z-index: 5;
}

.big-spinner {
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 5rem;
}

.counters {
    margin-top: 5rem;
    font-size: 1.5rem;
    opacity: 0.5;
}

.counters p {
    font-size: 1rem;
}

.dark {
    text-align: center;
    margin-top: 5rem;
    background-color: var(--darkest);
    padding-top: 2rem;
}

.discord {
    background-color: #2f3136;
    border: 3px solid #202225;
    border-radius: 1rem;
    height: 600px;
    margin-top: 5rem;
    overflow: hidden;
}

.chat {
    position: relative;
    background-color: #36393f;
    padding-left: 0!important;
    padding-right: 0!important;
}

.users {
    padding: 0;
}

.discord-header {
    position: relative;
    width: 100%;
    display: flex;
    border-bottom: 2px solid #2f3136;
    height: 2rem;
}
  
.bleh-blah {
    background-color: #d0d0d1;
    border-radius: 0.5rem;
}

.discord i {
    font-size: 0.6rem;
    margin-top: 0.1rem;
    opacity: 0.8;
}

.channels {
    margin-top: 1.5rem;
    text-align: left;
    padding: 0 10%;
}

.category, .channel {
    display: flex;
}

.category i, .channel i {
    margin-right: 10%;
}

.channel {
    margin-top: 10%;
    margin-left: 10%;
}


.chat-box {
    height: calc(85% - 2rem);
    position: relative;
    overflow: hidden;
}


.message-box {
    height: calc(20% - 2rem);;
    padding: 1rem;
}

.input-box {
    height: 100%;
    border-radius: 0.8rem;
    background-color: #40444b;
    margin: auto;
}

.discord-pfp {
    width: 1.8rem;
    height: 1.8rem;
    background-color: #d0d0d1;
    border-radius: 100%;;
}

.discord-bot-pfp {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 100%;
}

.discord-message {
    text-align: left;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.bot-pfp {
    text-align: right;
}

.bot-name {
    font-size: 0.65rem;
    font-weight: 700;
    color: white;
}

.bot-mark {
    font-size: 0.5rem;
    font-weight: 700;
    display: flex;
    background-color: #5865f2;
    width: fit-content;
    padding: 0.2rem 0.5rem;
    color: white;
    border-radius: 0.2rem;
    margin-left: 0.1rem;
    margin-top: -0.1rem;
    transform: scale(0.7)
}

.bot-mark i {
    margin-right: 0.2rem;
}

.discord p {
    margin: 0;
}

.discord-embed {
    font-size: 0.55rem;
    width: fit-content;
    max-width: 25rem;
    padding: 0.5rem 1rem;
    border-left: 3px solid #ff4d4d;
    border-radius: 0.4rem;
    background-color: #2f3136;
    color: white;
    transition: 0.4s;
}

.waifu-embed {
    max-width: 10rem;
    max-height: 15rem;
}

.embed-button {
    /* transform: scale(0.5); */
    font-size: 0.5rem;
    font-weight: 700;
}

.embed-spinner {
    width: 12rem;
    height: 22rem;
    position: relative;
}

.spinner-embed {
    width: 4rem;
    height: 4rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.small {
    color: #b2aeae;
    font-size: 0.5rem;
}

.feature {
    background-color: var(--sixth-color);
    height: fit-content;
    border-radius: 1rem;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 2rem;
    position: relative;
    z-index: 1;
}

.gallery-img {
    width: 17rem;
    margin: auto;
    margin-top: -4rem;
    z-index: 1;
    position: relative;
}

.gallery-action {
    text-align: center;
    margin: auto;
}

.mtn-2 {
    margin-top: -0.7rem;
}

.gallery .btn {
    z-index: 1;
    position: relative;
    transform: rotate(6deg);
    margin-top: -2rem;
}

.rotate {
    margin-right: -4rem;
    transform: rotate(-18deg)!important;
}

.gallery-tip {
    transform: rotate(-3deg);
}

.gallery-tip p {
    font-size: 1.15rem;
    color: var(--light);
}


.leaderboard {
  background-color: var(--sixth-color);
  color: white;
  border-radius: 1rem;
  padding: 0;
  padding-top: 1rem;
  padding-bottom: 2rem;
  margin: auto;
  position: relative;
  border: 2px solid var(--seventh-color);
}

.leaderboard h4 {
  text-transform: uppercase;
  font-weight: bold;
}

.leaderboard table {
  width: 100%;
}

.leaderboard i {
  margin-right: 1rem;
}

.leaderboard tr {
  height: 3rem;
}

.leaderboard tr:nth-child(odd) {
  background-color: var(--seventh-color);
}

.team-group p {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
}

.cardy {
    padding: 2rem 0.5rem;
    border-radius: 1rem;
    background-color: var(--secondary);
}

.team-card img {
    width: 60%;
    border-radius: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
}

.gallery-img-main {
    width: 100%;
}

.team-card a {
    font-size: 1rem;
    text-decoration: none;
    color: #fff;
}

.team-name {
  font-size: 1rem;
  margin-top: 1rem;
}

.team-role {
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0.2rem;
  width: 80%;
  margin: auto;
  color: #eee;
}

.team-role i {
  margin: auto;
  padding: 0;
}

.mobile-sidebar {
    height: 2.8rem;
    width: 100%;
    position: fixed;
    background-color: var(--secondary);
    z-index: 999;
    transition: 0.4s;
}

.burger {
    font-size: 2rem;
    margin-top: 0.2rem;
    margin-left: 0.8rem;
    cursor: pointer;
}

.burger:hover {
    color: var(--text-color-light);
}

.details-tags {
    flex-wrap: wrap;
}

@media only screen and (max-width: 991.5px) {
    .section-holder {
        padding: 1rem;
        padding-top: 4rem;
    }

    .big-panel {
        padding: 1rem;
    }

  .tip-pc {
    display: none;
  }
  .gallery-tip {
    transform: rotate(0deg);
  }
  .gallery .btn {
    transform: rotate(0deg)!important;
    margin-left: 0;
    margin-right: 0;
    margin-top: 1rem;
  }
  .rotate {
    transform: rotate(0deg);
  }
  .gallery-img {
    display: none;
  }
  .lb-left {
    transform: none;
    margin: auto;
    margin-top: 2rem;
  }
  .lb-right {
    transform: none;
    margin: auto;
    margin-top: 2rem;
  }
  .gallery-action {
    margin-top: 1rem;
  }
}

.form-text {
    color: var(--tip);
}